import { Alert, Button, Chip, CircularProgress } from "@mui/material";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { addProject, getProject, unsubscribeProjects } from "../firebase/firebase-setup";
import { useEffect, useState } from "react";
import { AlertDialog } from "../dialogs/alertdialog";
import { FaSpinner } from "react-icons/fa";
import { TbFileDownload } from "react-icons/tb";
import { NumericFormat } from "react-number-format";




export const Price = ({ formData }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [doc, updateDoc]= useState({});
const [price, updatePrice] = useState(0);

  const handleClose = () => {
    unsubscribeProjects();
    setDialogOpen(false);
  };


  useEffect(() => {

    const p = 3200 + (((formData?.height / 1000) ?? 0) * ((formData?.width /1000)?? 0) * 314)*(formData?.cladding?.price ?? 1)*(formData?.frame?.price ?? 1);

    updatePrice(p);

  },[formData])
  



  const text = <>
  {doc.spinner}
    <p>
    Bedankt voor uw bestelling van {doc.date}.<br />
    <p>
          <span style={{fontWeight: "bold"}}>Prijs: <NumericFormat value={Math.round(price)} displayType={'text'} thousandSeparator=" " prefix={'€ '} />          </span><br />
          <span>Excl. <NumericFormat value={Math.round(price * 0.21)} displayType={'text'} thousandSeparator=" " prefix={'€ '} />  BTW</span>
        </p>

    Binnen enkele ogenblikken kan u uw VXZ bestand hier downloaden.<br /> Gelieve het venster niet te sluiten.
    </p>
    <p>
    {doc.status}
    </p>
    <p>
    {doc.download}
    </p>


</>


  const create_project = () => {
    const id =  "STA_" + Math.round(Math.random() * 10000);
    const order = {
      title: id,
      configuratie: JSON.stringify(formData),
      status: "INITIAL",

    };

    addProject(order).then(() => {

        
        getProject(id,(project) => {
            const color = project.status == 'REQUESTED' ? 'warning' : project.status == 'FINISHED' ? 'success' :  project.status == 'ONGOING' ? 'warning' : 'error';
            updateDoc ({title: project.title,
                klant: 'John Doe',
                order: project.title,
                status: <Chip size='small' sx={{color: 'white'}} label={project.status || 'ERROR'} color={color} />,
                date:  (new Date((project?.createdAt?.seconds ?? 0) * 1000)).toLocaleString() ?? '' ,
                download: project.status == 'FINISHED' ? (<p>Download uw VXZ project: <a className='nocolorlink' href={project.url}><TbFileDownload /></a></p>) :  project.status == 'REQUESTED' ? '' : '',
                spinner: project.status == 'REQUESTED' ? (<p><CircularProgress /></p>) : ''
        })
          
        });

        console.log("besteld !");
    });

    setDialogOpen(true);

  };

  return (
    <div>
              <Alert sx={{m:'10px', borderRadius: '5px'}} severity="warning">
        Afbeeldingen zijn ter indicatie en tonen nooit 100% de werkelijkheid. Bestellingen worden niet werkelijk geplaatst. Deze opstelling is enkel een demo.
      </Alert>

      <div className="order-continue">
        <div>
          <h1 className="total-amount"><NumericFormat value={Math.round(price)} displayType={'text'} thousandSeparator=" " prefix={'€ '} />          </h1>
          <span>Excl. <NumericFormat value={Math.round(price * 0.21)} displayType={'text'} thousandSeparator=" " prefix={'€ '} />  BTW</span>
        </div>
        <Button
          size="large"
          variant="outlined"
          disableElevation
          className="order-button"
          startIcon={<MdOutlineShoppingCartCheckout />}
          onClick={create_project}
        >
          Bestellen
        </Button>
      </div>

      <AlertDialog
        text={text}
        handleClose={handleClose}
        title={"Uw bestelling " + doc.order}
        open={dialogOpen}
      />
    </div>
  );
};
