import * as THREE from "three";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import helvetiker from 'three/examples/fonts/helvetiker_regular.typeface.json';



// Function to create arrowheads
const createArrowhead = (position, direction,scene, length = 0.07) => {
    const transparant_blue = new THREE.MeshStandardMaterial({color: 0x0D8AFA, transparent: true, opacity: 1, shadowSide: true})

    const arrowGeometry = new THREE.CylinderGeometry(0.005,0.03, length, 24);

    const arrow = new THREE.Mesh(arrowGeometry, transparant_blue);

  // Position the arrowhead at the given position
  arrow.position.copy(position);
  
  // Align the arrowhead along the direction vector
  arrow.quaternion.setFromUnitVectors(new THREE.Vector3(0, -1, 0), direction);
  
  // Move the arrowhead along the direction to avoid overlap
  arrow.position.add(direction.clone().multiplyScalar(length / 2));
  
  scene.add(arrow);
  }
  

export const add_measure = (scene, point1, point2, text) => {
    const transparant_blue = new THREE.MeshStandardMaterial({color: 0x0D8AFA, transparent: true, opacity: 1, shadowSide: true})


  // Create a geometry from the points
  
  const geometry = new THREE.BufferGeometry().setFromPoints([point1, point2]);

  // Create a material for the line

  // Create the line
  // Create the cylinder geometry for the dimension line
  const direction = new THREE.Vector3().subVectors(point2, point1);

  const length = direction.length();
  const cylinderGeometry = new THREE.CylinderGeometry(0.005, 0.005, length, 8);
  const cylinder = new THREE.Mesh(cylinderGeometry, transparant_blue);

  // Position and rotate the cylinder to match the line segment
  cylinder.position.copy(point1).add(direction.multiplyScalar(0.5));
  cylinder.quaternion.setFromUnitVectors(
    new THREE.Vector3(0, 1, 0),
    direction.normalize()
  );
  scene.add(cylinder);

  // Add arrowheads to both points
  // Add arrowheads to both points
  if(length > 0.20){
  createArrowhead(point1, direction, scene);
  createArrowhead(point2, direction.negate(), scene);
}
  // Load a font and add dimension text
  // Load the default font

  /*
  const loader = new FontLoader();
  const font = loader.parse(helvetiker);

  // Create the text geometry
  const textGeometry = new TextGeometry(text + "", {
    font: font,
    size: 0.075,
    height: 0.001,
  });

  const textMaterial = new THREE.MeshBasicMaterial({
    color: 0x000000,
    transparent: true,
    opacity: 1,
  });
  const textMesh = new THREE.Mesh(textGeometry, textMaterial);

  // Position the text at the midpoint
  const midPoint = new THREE.Vector3()
    .addVectors(point1, point2)
    .multiplyScalar(0.5);
  textMesh.position.copy(midPoint);
  textMesh.position.y = textMesh.position.y + 0.1;
  textMesh.position.x = textMesh.position.x - 0.1;
  scene.add(textMesh);
  */
};
