import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Chip, useTheme } from '@mui/material';
import { TbFileDownload } from "react-icons/tb";
import { FaSpinner } from 'react-icons/fa';
import { getProjects } from '../firebase/firebase-setup';


const projects = [];

export const ProjectListDialog = ({onClose, selectedValue, open, projects }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const headers = ['Title','Klant','Order nummer','Status','VXZ file','Date']
    
    const [rows, updateRows] = React.useState([]);


    React.useEffect(()=> {
        const unsubscribe = getProjects((projects) => {

            const l = projects.map(project => {
                const color = project.status == 'REQUESTED' ? 'warning' : project.status == 'FINISHED' ? 'success' :  project.status == 'ONGOING' ? 'warning' : 'error';
                return {title: project.title,
                    klant: 'John Doe',
                    order: project.title,
                    status: <Chip size='small' sx={{color: 'white'}} label={project.status || 'ERROR'} color={color} />,
                    date:  (new Date((project?.createdAt?.seconds ?? 0) * 1000)).toLocaleString() ?? '' ,
                    download: project.status == 'FINISHED' ? <a className='nocolorlink' href={project.url}><TbFileDownload /></a> :  project.status == 'REQUESTED' ? <FaSpinner /> : ''
                }
            });
    
    
            updateRows(l); // Update state with projects data
        })
    }, [])
/*    ;*/

 

      

  console.log("open projectLogDialog")

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };


  return (
    <Dialog fullWidth='xl' maxWidth='xl' onClose={handleClose} open={open}>
      <DialogTitle>Laatste projecten</DialogTitle>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map(h => (<TableCell align='left'>{h}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.title}</TableCell>
              <TableCell align="left">{row.klant}</TableCell>
              <TableCell align="left">{row.order}</TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{row.download}</TableCell>
              <TableCell align="left">{row.date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
}
