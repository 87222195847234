


import React, { useEffect, useState } from 'react';

import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';

import { PiListChecks } from "react-icons/pi";
import { GiIBeam } from "react-icons/gi";

import { FaDoorOpen } from "react-icons/fa";
import { RxDimensions } from "react-icons/rx";
import { RiFontSize, RiWindowsLine } from "react-icons/ri";
import { GiElectricalSocket } from "react-icons/gi";
import { GiBrickWall } from "react-icons/gi";
import { PiCookingPot } from "react-icons/pi";
import { FaShower } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

import Select from "react-select";



import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { ReactComponent as Koto} from './koto.svg';
import { Chip, IconButton, Tab, Tabs } from '@mui/material';
import { addProject, getProjects } from '../firebase/firebase-setup';

import {ProjectListDialog} from '../dialogs/projectlist';
import { defaultForm } from './defaultForm';



const label = { inputProps: { 'aria-label': 'Switch demo' } };

const max_aantal_ramen = 1;
const max_aantal_deuren = 1;

export const Form = ({updateFormData}) => {
    const [value, setValue] = useState(0);

    const [formData, setFormData] = useState(defaultForm);



    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState("test");
  
    const handleClickOpen = () => {
      console.log("open dialog")
      setOpen(true);
    };
  
    const handleClose = (value) => {
      setOpen(false);
      setSelectedValue(value);
    };
  
    const setFocusValue = (type, index, selected_measure) => {
      if(type != formData.selected || formData.selected_index != index || formData.selected_measure != selected_measure) {
      setFormData({ ...formData, ['selected']: type,['selected_index']: index , ['selected_measure']: selected_measure });
    }

    }

    const [url,setUrl] = useState('');
    const [status,setStatus] = useState('INITIAL'); // INITIAL , LOADING , DONE
    const [projects,setProjects] = useState([]);

    useEffect(()=> {
      getProjects().then((projects) => {
        
        console.log(projects);
        setProjects(projects);
       })
         
    },[1])

    const handleSubmit = async (e) => {
        setStatus('LOADING');
        e.preventDefault();

        
        

        const requestBody = {
            name: "CC" + Math.floor(Math.random()*999999), 
            model : "Chalet_01",
            parameters: [
                {
                    name: "chalet_width",
                    value: formData.width
                },
                {
                    name: "chalet_height",
                    value: formData.height
                }
            ],
objects: [

    /*
    {object:"wall_type",name: "wall_type", parameters: {"TEST":500}, DX:0,DY:0, DZ:0, CODE: walltypes.find(x => x.name == formData.type).CODE, LIBRARY: walltypes.find(x => x.name == formData.type).LIBRARY},    
    {object:"wall",name: "set_wall", parameters: {"TEST":500}, DX:0,DY:0, DZ:0, "NEWPARAMS":`-|-||IN|0|${formData.wallHeight}`},
    {object:"door_01", name: ( formData.deur1? "set_door_01" : "remove_door_01"), parameters: {ROW: formData.door1Width,ROH:formData.door1Height}, DX:formData.door1X,DY:0, DZ:formData.door1Y},
    {object:"door_02", name: ( formData.deur2? "set_door_02" : "remove_door_02"), parameters: {ROW: formData.door2Width,ROH:formData.door2Height}, DX:formData.door2X,DY:0, DZ:formData.door2Y},
    {object:"door_03", name: ( formData.deur3? "set_door_03" : "remove_door_03"), parameters: {ROW: formData.door3Width,ROH:formData.door3Height}, DX:formData.door3X,DY:0, DZ:formData.door3Y},


    {object:"window_01", name: ( formData.raam1? "set_window_01" : "remove_window_01"), parameters: {ROW: formData.window1width,ROH:formData.window1height}, DX:formData.window1X,DY:0, DZ:formData.window1Y},
    {object:"window_02", name: ( formData.raam2? "set_window_02" : "remove_window_02"), parameters: {ROW: formData.window2width,ROH:formData.window2height}, DX:formData.window2X,DY:0, DZ:formData.window2Y},
    {object:"window_03", name: ( formData.raam3? "set_window_03" : "remove_window_03"), parameters: {ROW: formData.window3width,ROH:formData.window3height}, DX:formData.window3X,DY:0, DZ:formData.window3Y},


    {object:"stopcontact_01","name": ( formData.socket1?  "set_stopcontact_01" : "remove_stopcontact_01"), "parameters": {"TEST":500}, DX:formData.socket1X,DY:0, DZ:formData.socket1Y},
    {object:"stopcontact_02","name":  ( formData.socket2? "set_stopcontact_02" : "remove_stopcontact_02" ), "parameters": {"TEST":500},  DX:formData.socket2X,DY:0, DZ:formData.socket2Y},
    {object:"stopcontact_03","name":  ( formData.socket3? "set_stopcontact_03" : "remove_stopcontact_03"), "parameters": {"TEST":500}, DX:formData.socket3X,DY:0, DZ:formData.socket3Y}
    */
]


        };

 
        console.log(requestBody);
        //return;


        try {
            const response = await fetch('https://us-central1-poc-wandshop.cloudfunctions.net/wandshop', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                const url = await data.url;
                console.log(url);
                setUrl(url);
                setStatus('DONE')
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    const options = [


      //['BRICK-100','BRICK-110','BRICK-UK-103','BRICK-UK2-103','STUCCO-10','STUCCO-20','SI-BR-100']
      {displayName: 'Bricks red', materialName: '4', availableFor: "CLADDING", color: '0xFFFFFF', filename: "Bricks028_1K-JPG", size: {x: 1.20, y: 0.80} , vx_category: 'cladding', vx_name: 'BRICK-100', price:1.1 },
      {displayName: 'Bricks mixed', materialName: '4', availableFor: "CLADDING", color: '0x997950', filename: "Bricks031_1K-JPG", size: {x: 2.35, y: 0.35}, vx_category: 'cladding', vx_name: 'BRICK-110', price:1.15 },
      {displayName: 'Bricks light', materialName: '3', availableFor: "CLADDING", color: '0xFFFFFF', filename: "Bricks035_1K-JPG", size: {x: 2.35, y: 0.35} , vx_category: 'cladding', vx_name: 'BRICK-UK-103', price:1.2 },
      {displayName: 'Bricks white', materialName: '3', availableFor: "CLADDING", color: '0xFFFFFF', filename: "Bricks060_1K-JPG", size: {x: 0.90, y: 0.90}, vx_category: 'cladding', vx_name: 'BRICK-UK2-103', price:1.25  },
      {displayName: 'Bricks gray', materialName: '3', availableFor: "CLADDING", color: '0xFFFFFF', filename: "Bricks066_1K-JPG", size: {x: 0.65, y: 1.70}, vx_category: 'cladding', vx_name: 'STUCCO-10', price:1.3  },
      //{displayName: 'Wood Siding', materialName: '3', availableFor: "CLADDING", color: '0xFFFFFF', filename: "WoodSiding003_1K-JPG", size: {x: 0.50, y: 0.50}  },
      //{displayName: 'Red Wood Siding', materialName: '3', availableFor: "CLADDING", color: '0xFFFFFF', filename: "WoodSiding005_1K-JPG", size: {x: 1.30, y: 1.30}  },
      {displayName: 'Brick gray / white', materialName: '3', availableFor: "CLADDING", color: '0xFFFFFF', filename: "Bricks065_1K-JPG", size: {x: 0.40, y: 2.40} , vx_category: 'cladding', vx_name: 'SI-BR-100', price:1.05 },
      {displayName: 'Brick yellow', materialName: '3', availableFor: "CLADDING", color: '0xFFFFFF', filename: "Bricks063_1K-JPG", size: {x: 0.60, y: 1.70} , vx_category: 'cladding', vx_name: 'STUCCO-20', price:1 },

      
      
    
      {
        displayName: "STAAL 200",
        dikte: 200,
        availableFor: "FRAME",
        price: 1.2
      },
      {
        displayName: "STAAL 100",
        dikte: 100,
        availableFor: "FRAME",
        price: 1.1
      },
      {
        displayName: "STAAL 50",
        dikte: 50,
        availableFor: "FRAME",
        price: 1
      },

          
      ];

    const updateMaterial = (value,name) => {
        setFormData({ ...formData, [name]: value });
        console.log(formData);
    }


    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };



    const handleChange = (e) => {
        console.log(e.target.value)
        console.log(JSON.stringify(e.target.value));
        console.log(e.target.name)
        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log(formData);
    };

    const handleChangeArray = (e,i,type) => {
      const new_deuren = [...formData[type]];
      new_deuren[i][e.target.name] = Number(e.target.value);
      setFormData({ ...formData, [type]: new_deuren });
    }



    const add_deur = (type) => {
      
      const new_openings = [...formData[type]];
      let latest_opening_type = [...formData[type]].sort((a,b) => {return Number(b.x1) - Number(a.x1)})[0]
      if(!latest_opening_type){
        if(type == "deuren"){
          latest_opening_type =  {width: 1100, height: 2200, x1: 3200, y1: 100};
        }

        if(type=="ramen"){
          latest_opening_type =   {width: 800, height: 1500, x1: 800, y1: 2300};
        }
        
      }

      let latest_opening = [...formData["deuren"]].concat([...formData["ramen"]]).sort((a,b) => {return Number(b.x1) - Number(a.x1)})[0];
      if(!latest_opening){
        latest_opening = {x1: 300, width: 0};
      }
      console.log(JSON.stringify(latest_opening));

      new_openings.push({...latest_opening_type, ['x1']: Number(latest_opening.x1) + Number(latest_opening.width) + 300});
      console.log(new_openings)
      setFormData({ ...formData, [type]: new_openings });
      
    }

    const remove_deur = (type, i) => {
      const new_deuren = [...formData[type]].filter((x,j) => j !== i);
      setFormData({ ...formData, [type]: new_deuren });

    }

    const handleCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        console.log(formData);

    };

    updateFormData(formData);




    const steps = [
      /*  {
          label: 'Klanten informatie',
          sublabel: 'Alle informatie voor het opmaken van een offerte',
          content: <>
          
          <div className="section">
                    <h2><span><IoPersonOutline />&nbsp;Naam klant</span></h2>

                    <div className="form-group">
                        <input type="text" id="width" name="width" value={formData.width} onChange={handleChange} required />
                    </div>
                    
                </div>


                <div className="section">
                    <h2><span><IoMailOutline />&nbsp;Email klant</span></h2>

                    <div className="form-group">
                        <input type="text" id="width" name="width" value={formData.width} onChange={handleChange} required />
                    </div>
                    
                </div>


                </>
         
        },*/
        {
          label: 'Configuratie afmetingen',
          sublabel: 'Afmetingen die de basis vormen van het ontwerp',
          content: ''
         
        },
        
        {
          label: 'Deuren ',
          sublabel: 'Voeg openingen toe voor deuren',
          content: ''
        }     ,   {
          label: 'Ramen',
          sublabel: 'Voeg openingen toe voor ramen',
          content: ''
        },
      ];
      
      
      


    return (

          
<>

<div className='navButtons'>
<IconButton onClick={handleClickOpen}><PiListChecks /></IconButton>
</div>

<h1 className='titleform'><Koto className='logoKoto' />Wand configurator</h1>   


<form onSubmit={handleSubmit}>






    <div className='tabbox'>


      <Tabs
        orientation="vertical"
        role="navigation"
        value={value}
        onChange={handleChangeTab}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, width: '90px', borderColor: 'divider' }}
      >
        <Tab id='vertical-tab-0' style={{fontSize: "x-large"}} icon={<RxDimensions />}  />
        <Tab id='vertical-tab-1' style={{fontSize: "x-large"}} icon={<GiBrickWall />}  />
        <Tab id='vertical-tab-2' style={{fontSize: "x-large"}} icon={<RiWindowsLine />}  />
        <Tab id='kvertical-tab-3' style={{fontSize: "x-large"}} icon={<FaDoorOpen />}  />
        <Tab id='kvertical-tab-4' style={{fontSize: "x-large"}} icon={<IoPersonOutline />}  />
      </Tabs>
      <div className='tabcontent'>

      {value === 0 ? <>
      <div>


          
          <div className="section" onClick={() => setFocusValue('size',0)} onMouseLeave={()=> setFocusValue('',0)}
>
                    <h2><span><RxDimensions />&nbsp;Afmetingen wand</span></h2>

                    <div className='group'>
                    <div className="form-group">
                        <label htmlFor="width">Breedte (mm):</label>
                        <input type="number" id="width" name="width" value={formData.width} onChange={handleChange} required />
                    </div>
                    
                      
                        <div className="form-group">
                            <label htmlFor="height">Hoogte (mm):</label>
                            <input type="number" id="height" name="height" value={formData.height} onChange={handleChange} required />
                        </div>
                     { /*
                    <div className="form-group">
                        <label htmlFor="type">Type:</label>
                        <select id="type" name="type" value={formData.type} onChange={handleChange} required>
                            {walltypes.map(wt => (<option value={wt.CODE}>{wt.name}</option>))}
                            
                        </select>
                    </div> 
                    */
                }
                    </div>
                </div>





          

      </div></> : ''}


      {value === 1 ? <>
      <div>


      



                <div className="section" onClick={() => setFocusValue('gevel',0)} onMouseLeave={()=> setFocusValue('',0)}
>
                    <h2><span><GiBrickWall />&nbsp;Gevelbekleding</span></h2>
     <div className='group'>


     <div className="form-group">


                        <Select
                            name="cladding"
                            options={options.filter(o => o .availableFor == 'CLADDING')}
                            value={formData.cladding}
                            onChange={(e) => updateMaterial(e,"cladding")}
                            getOptionLabel={(option) => (option.displayName + ` (${option.vx_name})`)}
                            getOptionValue={(option) => option}
                        />

                    </div> 


                        </div>

                    </div> 


                    <div className="section" onClick={() => setFocusValue('pannen',0)} onMouseLeave={()=> setFocusValue('',0)}
>
                    <h2><span><GiIBeam />&nbsp;Frame</span></h2>
     <div className='group'>


     <div className="form-group">


                        <Select
                            name="frame"
                            options={options.filter(o => o .availableFor == 'FRAME')}
                            value={formData.frame}
                            onChange={(e) => updateMaterial(e,"frame")}
                            getOptionLabel={(option) => option.displayName}
                            getOptionValue={(option) => option}
                        />

                    </div> 


                        </div>

                    </div> 

          

      </div></> : ''}

      {value === 2 ? <>
      <div>


{ formData.ramen.map((deur,i) => 
          <div className="section" >
                              <h2><span><RiWindowsLine />&nbsp;RAAM {i+1}</span> <span onClick={() => remove_deur("ramen",i)}><MdDeleteForever className='delete' /></span> </h2>
          

                              <div className='group'>


<div className='group'>
<div className="form-group">
<label htmlFor="width">X1 (mm):</label>
<input onFocusCapture={()=> setFocusValue('',0)}  onFocus={() => setFocusValue('raam',i,'x1')} type="number" id="width"  name='x1' value={formData.ramen[i].x1} onChange={(e) => handleChangeArray(e,i,'ramen')} required />
</div>


<div className="form-group">
<label htmlFor="height">Y1 (mm):</label>
<input  onFocusCapture={()=> setFocusValue('',0)} onFocus={() => setFocusValue('raam',i,'y1')} type="number" id="height" name='y1' value={formData.ramen[i].y1} onChange={(e) => handleChangeArray(e,i,'ramen')} required />
</div>

</div>


</div>

<div className='group'>


<div className='group'>
<div className="form-group">
<label htmlFor="width">Breedte (mm):</label>
<input onFocusCapture={()=> setFocusValue('',0)} onFocus={() => setFocusValue('raam',i,'width')} type="number" id="width"  name='width' value={formData.ramen[i].width} onChange={(e) => handleChangeArray(e,i,'ramen')} required />
</div>


<div className="form-group">
<label htmlFor="height">Hoogte (mm):</label>
<input onFocusCapture={()=> setFocusValue('',0)} onFocus={() => setFocusValue('raam',i,'height')} type="number" id="height" name='height' value={formData.ramen[i].height} onChange={(e) => handleChangeArray(e,i,'ramen')} required />
</div>

</div>


</div>



</div> 


          

            )}

{formData.ramen.length < max_aantal_ramen ? (
<h2><span className='add' onClick={() => add_deur("ramen")}><IoMdAddCircleOutline />Voeg raam toe</span></h2>
) : ''}
          
        
                    

                    </div></> : ''}

{value === 3 ? <>
<div>
          
          { formData.deuren.map((deur,i) => 
          <div className="section" >
                              <h2><span><FaDoorOpen />&nbsp;DEUR {i+1}</span><span onClick={() => remove_deur("deuren",i)}><MdDeleteForever className='delete' /></span></h2>
                              <div className='group'>


<div className='group'>
<div className="form-group">
<label htmlFor="width">Breedte (mm):</label>
<input  onFocusCapture={()=> setFocusValue('',0)} onFocus={() => setFocusValue('deur',i,'width')} type="number" id="width"  name='width' value={formData.deuren[i].width} onChange={(e) => handleChangeArray(e,i,'deuren')} required />
</div>


<div className="form-group">
<label htmlFor="height">Hoogte (mm):</label>
<input  onFocusCapture={()=> setFocusValue('',0)}   onFocus={() => setFocusValue('deur',i,'height')} type="number" id="height" name='height' value={formData.deuren[i].height} onChange={(e) => handleChangeArray(e,i,'deuren')} required />
</div>

</div>


</div>



                              <div className='group'>


<div className='group'>
<div className="form-group">
<label htmlFor="width">X1 (mm):</label>
<input  onFocusCapture={()=> setFocusValue('',0)}  onFocus={() => setFocusValue('deur',i,'x1')} type="number" id="width"  name='x1' value={formData.deuren[i].x1} onChange={(e) => handleChangeArray(e,i,'deuren')} required />
</div>


<div className="form-group">
<label htmlFor="height">Y1 (mm):</label>
<input   onFocusCapture={()=> setFocusValue('',0)}  onFocus={() => setFocusValue('deur',i,'y1')} type="number" id="height" name='y1' value={formData.deuren[i].y1} onChange={(e) => handleChangeArray(e,i,'deuren')} required />
</div>

</div>


</div>



</div> 


          

            )}


{formData.deuren.length < max_aantal_deuren ? (
  <h2><span className='add'  onClick={() => add_deur("deuren")}><IoMdAddCircleOutline />Voeg deur toe</span></h2>
) : ''}
          

            

            
      </div></> : '' }
{value === 4 ? <>

  <div className="section" 
>
                    <h2><span><IoPersonOutline />&nbsp;Contact gegevens</span></h2>

                    <div className="form-group">
                        <label htmlFor="naam">Naam:</label>
                        <input type="text" id="naam" name="naam" value={formData.naam} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                            <label htmlFor="height">Adres:</label>
                            <input type="text" id="adres" name="adres" value={formData.adres} onChange={handleChange} required />
                            </div>

                            <div className="form-group">
                            <label htmlFor="btw">BTW-nummer:</label>
                            <input type="text" id="btw" name="btw" value={formData.btw} onChange={handleChange} required />
                            </div>
                     
                </div>

</> : '' }




      </div>
      </div>
 
  






















{/*
                <button disabled={!(status=='INITIAL')} type="submit"> {status == 'LOADING' ? (<div className='buttonloader'><CircularProgress />LOADING...</div>) : 'Ontwerp chalet'} </button><br /><br />

                {url != '' ? <a href={url} >Download project</a> : 'Fill in the form and submit the form... '}


           
*/
}
</form>      



    <div>



        <ProjectListDialog
          projects={projects}
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
    </div>



        </>

    );
};