import * as THREE from "three";
import * as BUI from "@thatopen/ui";
import * as OBC from "@thatopen/components";
import Stats from "stats.js";
import { useEffect, useRef } from "react";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

import TWEEN from 'three/addons/libs/tween.module.js';
import { initMesh } from "./createNewMeshes";
import { createOrFindEntityForAllMetaObjectMeshes, findMesh, init_exention } from "./custom-gltf-extension";
import { lights } from "./lights";
import { creatNewGroupArray, setMaterialsIntoArray } from "./materialHandler";
import { add_wall } from "./wall";
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { defaultForm } from "../form/defaultForm";


let metaSerialToMesh = null;
let metaObjectsMesh = null;
let materialToMeshMap = null;
let metaSerialToMetaObject = null;
let material_list = [];

// steel viewer!

export const Viewer = ({focusValue='default', formData = defaultForm}) => {

  console.log(formData);


  const containerRef = useRef(null);
  const loader = new GLTFLoader();



const onWindowResize = () => {
  if(window){
  console.log("test window resize")
  const newWidth = window.innerWidth;
  const newHeight = window.innerHeight;

  window.renderer.setSize(newWidth ,newHeight)

  window.camera.aspect = newWidth / newHeight
  window.camera.updateProjectionMatrix();
}

}

window.addEventListener("resize", onWindowResize, false)


const fitCameraToObject =  ( camera, object, offset, controls ) => {

	offset = 1.25;




	const boundingBox = new THREE.Box3();
  
  


	// get bounding box of object - this will be used to setup controls and camera
	boundingBox.setFromObject(object)

  let center = new THREE.Vector3( 0, 0, 0 );
  boundingBox.getCenter(center)
  console.log(center)

  let size = new THREE.Vector3( 0, 0, 0 );
   boundingBox.getSize(size);
  console.log(size);
  const distance_camera = Math.max( (size.x/2)/Math.tan(0.52)*1.3, 4);
  console.log(distance_camera)
  camera.position.set(center.x, center.y,distance_camera)

	  // set camera to rotate around center of loaded object
	  controls.target = center;

	  // prevent camera from zooming out far enough to create far plane cutoff
	  controls.maxDistance = 10

	  controls.saveState();
    controls.update()


}

const redraw_wall = (scene) => {
    if(scene){
      scene.traverse(child => {
        if(child.isMesh){
          child.material.visible = false;
        }
      })

    


  const object = add_wall(scene, formData);

};


}

useEffect(() => {

  if(window.scene){
    window.scene.traverse(child => {
      if(child.isMesh){
        child.material.visible = false;
      }
    })



const object = add_wall(window.scene, formData);

console.log("update camera")
  fitCameraToObject(window.camera,object,5.25, window.controls)
};

}, [formData.width, formData.height])

useEffect(() => {

  console.log("rerender the stuff!");
  if(window.scene && formData){
    redraw_wall(window.scene);
  }

}, [formData])


useEffect(() => {
  if (typeof window !== 'undefined') {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true  });
    renderer.gammaOutput = true;
    //renderer.setPixelRatio(window.devicePixelRatio)
    
    //renderer.physicallyCorrectLights = true;

    renderer.toneMappingExposure =0.145; // to allow for very bright scenes.
		
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    const canvas_width = (window.innerWidth / 4) * 3;
    const canvas_height = window.innerHeight;
    //renderer.setSize(, canvas_height);

    renderer.toneMapping = THREE.NoToneMapping;
    renderer.toneMappingExposure = 1;
    //renderer.outputColorSpace = THREE.SRGBColorSpace;
    
    
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.25;
    renderer.outputEncoding = THREE.sRGBEncoding;



    //renderer.physicallyCorrectLights = true;
    //renderer.shadowMap.enabled = true
    //renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    containerRef.current?.appendChild(renderer.domElement);

    // Add this inside the useEffect hook after initializing the renderer
    if (typeof window !== 'undefined') {
      const geometry = new THREE.BoxGeometry();
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

      // Create an axes helper with size 5
      let axesHelper = new THREE.AxesHelper(5);

      // Add the axes helper to the scene
      scene.add(axesHelper);

      // Create a grid helper with size 10 and divisions 10
let gridHelper = new THREE.GridHelper(50, 10, 0xc9c9c9, 0xc9c9c9);


// Add the grid helper to the scene
scene.add(gridHelper);



          lights(scene);

          //let camera = gltf.cameras[10];
          var camera = new THREE.PerspectiveCamera(60, 2, 0.1, 100);
          camera.position.set(5, 2, 5);
          //camera.lookAt(new THREE.Vector3(10,0,0))
                 

          const controls = new OrbitControls(camera, renderer.domElement);
          controls.listenToKeyEvents(window); // optional

          controls.screenSpacePanning = true;

          controls.target = new THREE.Vector3(5,2,0)
          controls.update();

          //controls.enableDamping = true;


          // Enable damping (inertia)
          controls.enableDamping = true; // Default is false

          // Set the damping factor (default is 0.25)
          controls.dampingFactor = 0.25; // Lower values give smoother, but slower response

// Adjust the pan speed (default is 1.0)
controls.panSpeed = 0.8; // Lower values make the panning slower and smoother

// Optionally adjust the rotate speed as well if you want smoother rotations
controls.rotateSpeed = 0.7; // Lower values make the rotation slower and smoother


          const composer = new EffectComposer(renderer);
          const renderPass = new RenderPass(scene, camera);
          composer.addPass(renderPass);


          // Add this function inside the useEffect hook
          const renderScene = () => {
            renderer.render(scene, camera);
            requestAnimationFrame(renderScene);
            //composer.render();

            
          };

          window.renderer = renderer;
          window.scene = scene;
          window.controls = controls;
          window.camera = camera;
          
          if(formData){
            const object = add_wall(scene, formData);
            //controls.update();
            
          }



          onWindowResize();
          renderScene();




          //scene.updateMatrixWorld(true);
    }
  }
}, [1]);


/*
  constuseEffect(() => {
    const container = document.getElementById("container");
    const components = new OBC.Components();
    const worlds = components.get(OBC.Worlds);


    const loader = new GLTFLoader();

    const world = worlds.create();

    world.scene = new OBC.SimpleScene(components);
    world.renderer = new OBC.SimpleRenderer(components, container);
    world.camera = new OBC.SimpleCamera(components);

    components.init();

    // Load IFC via fragments
    const fragments = components.get(OBC.FragmentsManager);
    const fragmentIfcLoader = components.get(OBC.IfcLoader);


    
    (async () => {
      await fragmentIfcLoader.setup();
      fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
      fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true;

      const file = await fetch("/Chalet_01.ifc");
      const data = await file.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await fragmentIfcLoader.load(buffer);
      model.name = "example";
      //world.scene.three.add(model);
      // for (const mesh of model.children) {
      //   culler.add(mesh as any);
      // }

      const material = new THREE.MeshLambertMaterial({ color: "#6528D7" });
      const geometry = new THREE.BoxGeometry();
      const cube = new THREE.Mesh(geometry, material);
      //world.scene.three.add(cube);




      world.scene.setup();

      // Load a glTF resource
      loader.load(
        // resource URL
        'model.glb',
        // called when the resource is loaded

        (gltf) => {
          console.log('loaded!');
          console.log(gltf);


          const model = gltf.scene;
          model.visible = true;
          world.scene.three.add(model);

        },
        () => {},
        (error) => {
          console.log(error);
        }
      );

      
      setInterval(() => {
        console.log("position");
        console.log(world.camera.controls.getPosition());
        console.log(world.camera.controls.getTarget());
      }, 2000);



        



      //world.camera.controls.setLookAt(10, 10, 10, 0, 0, 0);

      world.scene.three.background = null;

      window.camera = world.camera;
    })();
  }, []);

  */

  return <div class="full-screen" ref={containerRef}></div>;
};
